<template>
  <b-modal
    id="message-modal"
    ref="message-modal"
    no-close-on-backdrop
    hide-footer
    title="บันทึกข้อความ"
    @show="resetModal"
    @hidden="resetModal"
    class="p-0"
  >

    <b-form-group
      label="ข้อความ"
    >
      <b-form-textarea
        v-model="message"
        type="text"
        placeholder="รายละเอียดข้อความ"
      ></b-form-textarea>
    </b-form-group>

    <b-form-file
      ref="inputFile"
      v-model="file"
      class="mt-1 mb-3"
      size="sm"
      placeholder="แนบไฟล์รูป"
      browse-text="เลือกรูป"
      accept="image/jpeg, image/png"
    ></b-form-file>

    <div class="d-flex justify-content-end">
      <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
      <b-button :disabled="isProcess" type="submit" variant="primary" size="sm" @click="handleSubmit">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
    </div>
  </b-modal>
</template>
<script>
import MessageService from '@/services/MessageService'
import Swal from 'sweetalert2'

export default {
  name: 'MessageModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      message: null,
      file: null
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = null
      this.message = this.data.message
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.isProcess = false
    },
    showModal() {
      this.$refs['message-modal'].show()
    },
    hideModal() {
      this.message = null
      this.$refs['message-modal'].hide()
    },
    handleSubmit() {

      if(!this.message) {
        return Swal.fire({
          title: 'กรุณาใส่ข้อความ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      this.isProcess = true
      this.input = {
        ...this.data,
        message: this.message
      }

      let formData = new FormData()
      for (const [key, value] of Object.entries(this.input)) {
        formData.append(key, JSON.stringify(value||''))
      }

      if(this.file) {
        formData.append('image', this.file)
      }

      MessageService.save(formData)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>
#message-modal {

}
</style>
