<template>
  <b-modal
    id="report-agent-madal"
    ref="report-agent-madal"
    no-close-on-backdrop
    hide-footer
    :title="title"
    @show="resetModal"
    @hidden="resetModal"
    size="xl"
  >

    <template #modal-header="{ close }">
      <div>
        <h5 class="mb-0 text-primary">{{dateText}}</h5>
        <div class="text-info">{{title}}</div>
      </div>
      <b-button size="sm" variant="outline" @click="close()">
        <i class="fas fa-times"></i>
      </b-button>
    </template>

    <table class="table table-sm table-bordered mb-0">
      <thead>
        <tr>
          <th>หวย</th>
          <th>ยอดขาย</th>
          <th>ถูกรางวัล</th>
          <!-- <th>ค่าคอมฯ</th>
          <th>บริษัทฯ</th> -->
          <th>คงเหลือ</th>
        </tr>
      </thead>
      <tbody v-for="(group, gKey) in groups" :key="gKey">
        <tr>
          <th colspan="4" class="text-info text-left">{{group[0].group.item_name}}</th>
        </tr>
        <tr v-for="(item, key) in group" :key="key">
          <td class="text-center">{{item.item_name}}</td>
          <td class="text-right" v-html="numberFormat(item.amount.sales)"></td>
          <td class="text-right" v-html="numberFormat(-item.amount.won)"></td>
          <td class="text-right" v-html="numberFormat(item.amount.balance, 2)"></td>
        </tr>
        <tr style="font-weight: bold;" v-if="groupsLength > 1 && group.length > 1">
          <td class="text-center">รวม {{group[0].group.item_name}}</td>
          <th class="text-right" v-html="numberFormat(totalGroups[gKey].sales)"></th>
          <th class="text-right" v-html="numberFormat(totalGroups[gKey].won)"></th>
          <th class="text-right" v-html="numberFormat(totalGroups[gKey].balance)"></th>
        </tr>
      </tbody>
      <tfoot style="border-top: 3px double rgb(204, 204, 204);">
        <tr style="font-size: 110%; background-color: #C3E6CB;">
          <th>รวม</th>
          <th class="text-right"><span class="pr-2" v-html="numberFormat(totalSale)"></span></th>
          <th class="text-right"><span class="pr-2" v-html="numberFormat(totalWon)"></span></th>
          <th class="text-right"><span class="pr-2" v-html="numberFormat(totalBalance, 2)"></span></th>
        </tr>
      </tfoot>
    </table>
  </b-modal>
</template>
<script>
import RecordService from '@/services/RecordService'
import Swal from 'sweetalert2'

import _ from 'lodash'

export default {
  name: 'RecordAgentModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    markets: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      input: {},
      isProcess: false,
      needReload: false
    }
  },
  computed: {
    groups() {
      if(!this.data)
        return []

      const markets = this.markets.filter((market)=>{
        return this.input.markets[market._id]
      })
      .map((market)=>{
        market['amount'] = this.input.markets[market._id].amount
        return market
      })

      const sortMarkets = _.sortBy(markets, (market)=>{
        return parseInt(market.group.sort||0)
      })

      return _.groupBy(sortMarkets, 'owners.mGroupId')
    },
    dateText() {
      return `วันที่ ${this.data?.date}`
    },
    title() {
      return `เว็บ: ${this.data?.web?.item_name} - เอเย่นต์: ${this.data?.agent?.item_name}`
    },
    totalSale() {
      let total = 0
      for (const key in this.input.markets) {
        total += parseFloat(this.input.markets[key].amount.sales || 0)
      }
      return total
    },
    totalWon() {
      let total = 0
      for (const key in this.input.markets) {
        total += parseFloat(this.input.markets[key].amount.won || 0)
      }
      return -total
    },
    totalCommission() {
      // let total = 0
      // for (const key in this.input.markets) {
      //   total += parseFloat(this.input.markets[key].amount.commission || 0)
      // }
      // return total
      return this.data?.agent?.amount?.grandCommission || 0
    },
    totalCompany() {
      // let total = 0
      // for (const key in this.input.markets) {
      //   total += parseFloat(this.input.markets[key].amount.company || 0)
      // }
      // return total
      return this.data?.agent?.amount?.company || 0
    },
    totalBalance() {
      return this.data?.agent?.amount?.balance || 0
    },
    totalGroups() {
      let totals = {}

      for (const gkey in this.groups) {
        totals[gkey] = this.groups[gkey].reduce((total, market)=>{
          total.sales += parseFloat(this.input.markets[market._id].amount.sales||0)
          total.won -= parseFloat(this.input.markets[market._id].amount.won||0)
          total.balance += parseFloat(this.input.markets[market._id].amount.balance||0)
          total.commission += parseFloat(this.input.markets[market._id].amount.commission||0)
          total.company += parseFloat(this.input.markets[market._id].amount.company||0)
          return total
        }, {
          sales: 0,
          won: 0,
          balance: 0,
          commission: 0,
          company: 0
        })
      }

      return totals
    },
    groupsLength() {
      let size = 0
      for (const key in this.groups) {
        if (this.groups.hasOwnProperty(key)) size++;
      }
      return size;
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = JSON.parse(JSON.stringify(this.data.agent))
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.isProcess = false
    },
    showModal() {
      this.$refs['report-agent-madal'].show()
    },
    hideModal() {
      this.$refs['report-agent-madal'].hide()
    },
    numberClass(number) {
      if(!number || number==='-')
        return ''

      number = parseFloat(number)
      if(number < 0)
        return 'text-danger'

      if(number > 0)
        return 'text-success'

      return ''
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#report-agent-madal {

  input[type=number] {
    color: blue;
  }

  .modal-xl {
    max-width: 800px;

    .modal-body {
      padding: 0;

      .table {
        th {
          width: 20%;
          text-align: center;
        }
        td {
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
