<template>
  <b-modal
    id="record-agent-other-item-madal"
    ref="record-agent-other-item-madal"
    no-close-on-backdrop
    hide-footer
    :title="title"
    @show="resetModal"
    @hidden="resetModal"
  >

    <div class="p-2">
      <b-form-group
        label="รายการ"
      >
        <b-form-select v-model="selected" :options="options"></b-form-select>
      </b-form-group>

      <b-form-group
        label="จำนวน"
      >
        <b-form-input
          v-model="amount"
          :number="true"
          type="number"
          size="xl"
          :class="numberClass(amount)"
          placeholder="ใส่จำนวนเงิน"
          v-on:keydown="keypress"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="รายละเอียด"
      >
        <b-form-input
          v-model="description"
          type="text"
          placeholder="ใส่รายละเอียด"
        ></b-form-input>
      </b-form-group>
    </div>

    <div class="d-flex justify-content-end p-2">
      <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
      <b-button :disabled="isProcess" type="submit" variant="primary" size="sm" @click="handleSubmit">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
    </div>
  </b-modal>
</template>
<script>
import RecordService from '@/services/RecordService'

import Swal from 'sweetalert2'

export default {
  name: 'RecordOtherModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object
    },
    items: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      isProcess: false,
      needReload: false,
      selected: null,
      amount: null,
      description: null
    }
  },
  computed: {
    title() {
      return `บันทึก สาเหตุยอดขาด/เกิน`
    },
    options() {
      let options = [{
        value: null,
        text: '=== เลือกรายการ ==='
      }]
      for(const item of this.items) {
        options.push({
          value: item._id,
          text: item.item_name
        })
      }

      return options
    },
    AccountId() {
      return this.$route.params?.accountId || null
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    },
    data() {
      this.input = null
      this.selected = this.data.item_id
      this.amount = this.data.amount.number
      this.description = this.data.description
    }
  },
  methods: {
    resetModal() {
      this.needReload = false
      this.isProcess = false
    },
    showModal() {
      this.$refs['record-agent-other-item-madal'].show()
    },
    hideModal() {
      this.selected = null
      this.amount = null
      this.description = null
      this.$refs['record-agent-other-item-madal'].hide()
    },
    handleSubmit() {

      let selected = this.items.find((item)=>{
        return this.selected === item._id
      })

      if(!selected) {
        return Swal.fire({
          title: 'กรุณาเลือกรายการ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      selected = {
        ...selected,
        otherId: selected._id
      }

      if(!this.amount && this.amount!==0) {
        return Swal.fire({
          title: 'กรุณาระบุจำนวน',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      console.log(selected, this.amount)
      this.input = {
        ...this.data,
        amount: {
          number: parseFloat(this.amount)
        },
        item_id: selected?._id,
        item_name: selected.item_name,
        item_type: selected.item_type,
        options: selected,
        description: this.description
      }

      if(this.data?._id) {
        this.update()
      }else{
        this.save()
      }
    },
    save() {
      this.isProcess = true
      RecordService.saveOtherItem(this.input, this.AccountId)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    update() {
      this.isProcess = true
      RecordService.update(this.data._id, this.input, this.AccountId)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.needReload = true
          this.hideModal()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    numberClass(number) {
      if(!number || number==='-')
        return ''

      number = parseFloat(number)
      if(number < 0)
        return 'text-danger'

      if(number > 0)
        return 'text-success'

      return ''
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    keypress(event) {
      if(['Space'].includes(event.code)) {
        event.preventDefault()
      }
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', '-', '.'].includes(event.key)) {
        event.preventDefault()
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss">
#record-agent-other-item-madal {

  input[type=text] {
    color: blue;
  }

  .modal-dialog {
    .modal-body {
      padding: 0;
    }
  }
}
</style>
