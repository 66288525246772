<template>
  <div id="branch-report-date" class="container-fluid">

    <div class="d-flex align-items-center justify-content-between">
      <h4 class="mb-0 text-primary">{{accountName}}</h4>
      <div class="d-flex align-items-center">
        <h5 class="text-primary m-0">วันที่</h5>
        <div class="pl-2 pb-1">
          <div role="group" class="input-group mb-2 mr-sm-2 mb-sm-0">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="far fa-calendar-alt"></i></div>
            </div>
            <flat-pickr
              :config="datepickerConfig"
              v-model="date"
              class="form-control form-control-sm"
            />
          </div>
        </div>
      </div>
    </div>
    <hr>

    <div>ยอดคีย์ตามเว็บ</div>
    <div class="card">
      <table class="table table-sm table-info table-bordered mb-0" v-for="(web, index) in Websites" :key="index">
        <thead>
          <tr>
            <th colspan="15" style="background-color: #eee">เว็บ <span class="text-primary">{{web.item_name}}</span></th>
          </tr>
          <tr>
            <th rowspan="2" class="text-center">รหัสเอเย่นต์</th>
            <th colspan="3" class="text-center">ยอดเว็บ</th>
            <th colspan="4" class="text-center">เอเย่นต์</th>
            <th colspan="4" class="text-center">บริษัทฯ</th>
            <th colspan="2" class="text-center">สรุป ได้-เสีย</th>
            <th rowspan="2" width="5%" class="text-center"></th>
          </tr>
          <tr>
            <td width="6%" class="text-center">ยอดขาย</td>
            <td width="6%" class="text-center">ยอดจ่าย</td>
            <td width="6%" class="text-center">คงเหลือ</td>

            <td width="6%" class="text-center">ถือหุ้น</td>
            <td width="6%" class="text-center">ถูกรางวัล</td>
            <td width="6%" class="text-center">คอมฯ</td>
            <td width="6%" class="text-center">รวม</td>

            <td width="6%" class="text-center">ถือหุ้น</td>
            <td width="6%" class="text-center">ถูกรางวัล</td>
            <td width="6%" class="text-center">คอมฯ</td>
            <td width="6%" class="text-center">รวม</td>

            <td width="6%" class="text-center">Company</td>
            <td width="6%" class="text-center">สุทธิ</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(agent, aIndex) in web.agents" :key="aIndex">
            <td class="font-weight-bold text-info text-center">{{agent.item_name}}</td>
            <td class="text-right" v-html="numberFormat(agent.amount.sales)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.won)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.balance)"></td>

            <td class="text-right" v-html="numberFormat(agent.amount.agentHold)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.agentWon)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.agentCommission)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.agentTotal)"></td>

            <td class="text-right" v-html="numberFormat(agent.amount.ownerHold)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.ownerWon)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.ownerCommission)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.ownerTotal)"></td>

            <td class="text-right" v-html="numberFormat(agent.amount.companyWinloss)"></td>
            <td class="text-right" v-html="numberFormat(agent.amount.ownerWinloss)"></td>

            <td class="text-center">
              <button class="btn btn-link btn-sm p-0" @click="toggleAgentData(web, agent)"><i class="fas fa-list"></i></button>
            </td>
          </tr>
          <tr v-if="!web.agents.length">
            <td colspan="15" class="text-center bg-light">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="web.agents.length">
          <tr>
            <th class="text-center">รวม</th>
            <th class="text-right" v-html="numberFormat(web.amount.sales)"></th>
            <th class="text-right" v-html="numberFormat(web.amount.won)"></th>
            <th class="text-right" v-html="numberFormat(web.amount.balance)"></th>

            <td class="text-right" v-html="numberFormat(web.amount.agentHold)"></td>
            <td class="text-right" v-html="numberFormat(web.amount.gabAmount)"></td>
            <td class="text-right" v-html="numberFormat(web.amount.commission)"></td>
            <td class="text-right" v-html="numberFormat(web.amount.grandCommission)"></td>

            <td class="text-right" v-html="numberFormat(web.amount.ownerHold)"></td>
            <td class="text-right" v-html="numberFormat(web.amount.ownerWon)"></td>
            <td class="text-right" v-html="numberFormat(web.amount.ownerCommission)"></td>
            <td class="text-right" v-html="numberFormat(web.amount.ownerTotal)"></td>

            <td class="text-right" v-html="numberFormat(web.amount.companyWinloss)"></td>
            <td class="text-right" v-html="numberFormat(web.amount.ownerWinloss)"></td>
            <th class="text-right" colspan="1"></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <span>สาเหตุยอด ขาด/เกิน</span>
      <!-- <button class="btn btn-sm btn-link" @click="addItem">เพิ่ม</button> -->
    </div>
    <div class="card">
      <table class="table table-sm table-warning table-bordered mb-0">
        <thead>
          <tr>
            <th style="width: 25%;">รายการ</th>
            <th style="width: 20%;" class="text-right">จำนวน</th>
            <th style="width: 20%;" class="text-center">ผู้บันทึก</th>
            <th>รายละเอียด</th>
            <!-- <th style="width: 8%"></th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in Others" :key="item._id">
            <td class="text-info">{{ item.item_name }}</td>
            <td class="text-right text-success" v-html="numberFormat(item.amount.number)"></td>
            <td class="text-center">{{item.logs.by || '-'}}</td>
            <td>{{ item.description }}</td>
            <!-- <td class="text-right"><button class="btn btn-link btn-sm p-0" @click="editItem(item)">แก้ไข</button>/<button class="btn btn-link btn-sm p-0 text-danger" @click="deleteItem(item)">ลบ</button></td> -->
          </tr>
          <tr v-if="!Others.length">
            <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="Others.length">
          <tr>
            <th class="text-center">รวม</th>
            <th class="text-right text-success" v-html="numberFormat(totalOtherAmount)"></th>
            <th colspan="2"></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <div>
      <h5 class="text-info">สรุปยอดประจำวัน</h5>
    </div>
    <div class="card">
      <table class="table table-sm mb-0 table-success table-bordered">
        <thead>
          <tr>
            <th style="width: 25%;">รายการ</th>
            <th style="width: 20%;" class="text-right">จำนวน</th>
            <th style="width: 20%;" class="text-center">ผู้บันทึก</th>
            <th class="text-center">รายละเอียด</th>
          </tr>
        </thead>
        <tbody>
          <tr style="font-weight: bold; font-size: 120%;">
            <td>เงินตั้งต้น</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(startAmount)"></td>
            <td class="text-center">{{ saveStartAmountBy }}</td>
            <td class="text-center"><small>{{ saveStartNote }}</small></td>
          </tr>
          <tr>
            <td>ยอดขาย</td>
            <td class="text-right" v-html="numberFormat(totalSale)"></td>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td>ยอดจ่าย</td>
            <td class="text-right" v-html="numberFormat(totalWon)"></td>
            <td colspan="2"></td>
          </tr>
          <tr style="font-weight: bold; font-size: 120%;">
            <td>เงินที่ควรจะมี</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(lottoBalance)"></td>
            <td colspan="2"></td>
          </tr>

          <tr>
            <td colspan="4" style="height: 35px;"></td>
          </tr>
          <tr>
            <td>ขาด/เกิน</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(totalOther)"></td>
            <td colspan="2"></td>
          </tr>
          <tr style="font-weight: bold; font-size: 120%;">
            <td>เงินที่มีจริง</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(realBalance)"></td>
            <td colspan="2"></td>
          </tr>

          <tr>
            <td colspan="4" style="height: 35px;"></td>
          </tr>

          <tr style="font-size: 120%;">
            <td>โอนกลับบัญชีกลาง</td>
            <td class="text-right font-weight-bold" v-html="numberFormat(refundAmount)"></td>
            <td class="text-center">{{ saveRefundAmountBy }}</td>
            <td class="text-center"><small>{{ saveRefundNote }}</small></td>
          </tr>
        </tbody>
        <tfoot>
          <tr style="font-size: 180%;">
            <th>คงเหลือ</th>
            <th class="text-right font-weight-bold" v-html="numberFormat(grandTotal, '0')"></th>
            <td colspan="2"></td>
          </tr>
        </tfoot>
      </table>
    </div>

    <div v-if="reportId">
      <div v-if="logs.length > 0">
        <span>บันทึกกิจกรรม</span>
        <table class="table table-sm table-striped">
          <tbody>
            <tr v-for="(item, index) in logs" :key="index">
              <td>
                <small>{{ item.logs.date }} โดย {{ item.logs.username }}</small> - <span class="text-info">{{ item.logs.message }}</span>
                <div v-if="item.logs.image" class="p-2">
                  <a :href="item.logs.image" target="_blank"><img :src="item.logs.image" style="max-width: 100%; width: 250px;"></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-between">
        <button class="btn btn-lg btn-info" @click="addMessage">บันทึกข้อความ</button>
        <button v-if="reportStatus.code === 'PendingReview'" class="btn btn-lg btn-success" @click="confirmReport">ยืนยันข้อมูล</button>
      </div>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />

    <ReportAgentModal :data="agentData" :markets="MarketItems" :is-show="isShowAgentModal" @close="modalClosed" />
    <RecordOtherModal :data="otherData" :items="OtherItems" :is-show="isShowOtherModal" @close="modalClosed" />
    <MessageModal :data="messageData" :is-show="isShowMessageModal" @close="modalClosed" />

  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import moment from '@/helpers/moment'
import ConvStatus from '@/helpers/conv-status'

import Swal from 'sweetalert2'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import RecordService from '@/services/RecordService'
import ReportService from '@/services/ReportService'
import TransactionService from '@/services/TransactionService.js'

import ReportAgentModal from './components/ReportAgentModal'
import RecordOtherModal from '@/views/branch/record/components/RecordOtherModal'
import MessageModal from '@/views/components/MessageModal'

export default {
  name: 'AdminReportDateBranch',
  components: {
    ReportAgentModal,
    RecordOtherModal,
    MessageModal,
    flatPickr,
    Loading
  },
  data() {
    return {
      isLoading: false,
      date: this.$route.params.date || moment().add(-5, 'h').format("YYYY-MM-DD"),
      Account: null,
      Websites: [],
      Others: [],
      OtherItems: [],
      Summary: null,
      Activities: [],
      MarketGroups: [],
      Markets: [],

      isShowAgentModal: false,
      agentData: null,

      isShowOtherModal: false,
      otherData: null,

      isShowMessageModal: false,
      messageData: null,

      datepickerConfig: {
        enableTime: false,
        dateFormat: 'Y-m-d',
        maxDate: moment().format("YYYY-MM-DD")
      }
    }
  },
  computed: {
    MarketItems() {
      return this.Markets.map((market)=>{
        market['group'] = this.MarketGroups.find((group)=>{
          return group._id === market?.owners?.mGroupId
        })
        return market
      })
    },
    totalOtherAmount() {
      return this.Others.reduce((total, o)=>{
        return total + o.amount.number
      }, 0)
    },
    /**
     * เงินตั้งต้น
     */
    startAmount() {
      return this.Summary?.start?.amount || 0.00
    },
    /**
     * ยอดขาย
     */
    totalSale() {
      return this.Summary?.sales || 0.00
    },
    /**
     * ยอดจ่าย
     */
    totalWon() {
      return this.Summary?.won || 0.00
    },
    /**
     * เงินที่ควรจะมี
     */
    lottoBalance() {
      return this.Summary?.lottoBalance || 0.00
    },
    /**
     * ขาด/เกิน
     */
    totalOther() {
      return this.Summary?.other || 0.00
    },
    /**
     * เงินที่มีจริง
     */
    realBalance() {
      return this.Summary?.realBalance || 0.00
    },
    // total() {
    //   return this.Summary?.total || 0.00
    // },
    accountName() {
      return this.Account?.name
    },
    grandTotal() {
      return this.Summary?.grandTotal || 0.00
    },
    startAmount() {
      return this.Summary?.start?.amount || 0.00
    },
    refundAmount() {
      return this.Summary?.refund?.amount || 0.00
    },
    saveStartAmountBy() {
      return this.Summary?.start?.logs.by || '-'
    },
    saveRefundAmountBy() {
      return this.Summary?.refund?.logs.by || '-'
    },
    saveStartNote() {
      return this.Summary?.start?.note
    },
    saveRefundNote() {
      return this.Summary?.refund?.note
    },
    reportId() {
      return this.Summary?._id
    },
    reportStatus() {
      const status = ConvStatus.report(this.Summary?.status)
      console.log('status', status)
      return status
    },
    logs() {
      return this.Activities || []
    }
  },
  watch: {
    date() {
      this.getRecord()
    }
  },
  methods: {
    getRecord() {
      this.isLoading = true
      RecordService.get(this.date, this.$route.params.accountId)
      .then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Account = response.data.Account
          this.Websites = response.data.Websites
          this.Others = response.data.Others
          this.Summary = response.data.Summary
          this.Activities = response.data.Activities
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: e?.message || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        console.log('finally')
        this.isLoading = false
      })
    },
    getOtherItems() {
      TransactionService.gets('OtherItem').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.OtherItems = response.data
        }
      })
    },
    getMarketGroups() {
      TransactionService.gets('MarketGroup').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.MarketGroups = response.data
        }
      })
    },
    getMarkets() {
      TransactionService.gets('Market').then((response)=>{
        console.log(response)
        if(response.status === 'success') {
          this.Markets = response.data
        }
      })
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    toggleAgentData(web, agent) {
      this.agentData = {web, agent, date: this.date}
      // console.log(this.agentData.agent.amount.commission)
      this.isShowAgentModal = true
    },
    modalClosed(needReload) {
      this.isShowAgentModal = false
      this.isShowOtherModal = false
      if(needReload) {
        this.getRecord()
      }
    },
    addItem() {
      this.otherData = {
        accountId: this.Account?._id,
        _id: null,
        date: this.date,
        amount: {
          number: null
        },
        item_id: null,
        item_name: null,
        item_type: null,
        markets: null,
        options: null
      }
      this.isShowOtherModal = true
    },
    editItem(item) {
      this.otherData = {
        accountId: this.Account?._id,
        _id: item._id,
        date: item.date,
        amount: item.amount,
        item_id: item.options.otherId,
        item_name: item.options.item_name,
        item_type: item.options.item_type,
        markets: item.markets,
        options: item.options
      }
      this.isShowOtherModal = true
    },
    deleteItem(item) {
      console.log(item)
      Swal.fire({
        title: 'ยืนยันลบ?',
        text: `${item.item_name} ${item.amount.number}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
        customClass: {
          actions: 'justify-content-around'
        }
      }).then((result) => {
        if(result.isConfirmed) {
          RecordService.delete(item._id, this.Account?._id)
          .then((response)=>{
            console.log(response)
            if(response.status === 'success') {
              this.getRecord()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: e?.name || 'ผิดพลาด!',
              text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    },
    addMessage() {
      this.messageData = {
        owners: {
          accountId: this.Account._id,
          userId: null,
          summaryId: this.Summary._id,
          transactionId: null
        },
        message: ''
      }
      this.isShowMessageModal = true
    },
    confirmReport() {
      Swal.fire({
        title: 'ข้อมูลถูกต้อง!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
        customClass: {
          actions: 'justify-content-around'
        }
      }).then((result) => {
        if(result.isConfirmed) {
          ReportService.confirmReport(this.Summary?._id)
          .then((response)=>{
            console.log(response)
            if(response.status === 'success') {
              this.getRecord()
              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ยืนยันเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            console.log(e)
            Swal.fire({
              title: e?.name || 'ผิดพลาด!',
              text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'ยืนยันไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    }
  },
  mounted() {
    this.getMarketGroups()
    this.getMarkets()
    this.getRecord()
    this.getOtherItems()
  }
}
</script>
<style lang="scss" scoped>
#branch-report-date {
  padding-bottom: 100px;
  position: relative;
}
</style>
