import 'regenerator-runtime/runtime'
import api from "@/services/apiService"

class MessageService {

  /**
   * บันทึก
   */
  save(data) {
    return new Promise((resolve, reject) => {
      api.post(process.env.VUE_APP_BACKEND_API + 'message', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(error) {
        console.log(error)
        reject(error?.data || error)
      });
    });
  }

}

export default new MessageService()
